import React from "react"
import styled from "styled-components"

const DescriptionText = styled.h2`
  font-weight: 300;
`

const GalleryDetailInfo = props => {
  return (
    <div>
      <DescriptionText>
        <em>{props.details.title}</em>
      </DescriptionText>
      <DescriptionText>{props.details.year}</DescriptionText>
      <DescriptionText>{props.details.medium}</DescriptionText>
      <DescriptionText>{props.details.dimensions}</DescriptionText>
    </div>
  )
}

export default GalleryDetailInfo
