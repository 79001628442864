import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import colors from "../styles/colors"
import { slugify } from "../utils/utils"

const ArrowLink = styled(Link)`
  text-decoration: none;
`

const Arrow = styled.h1`
  font-weight: 100;
  color: ${colors.grey};
  font-size: 4rem;
  margin-right: 3rem;

  :active {
    color: black;
  }
`

const ArrowsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

class GalleryNavigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentIndex: props.galleryData.index,
      nextIndex: setInitialState("next", props.galleryData),
      prevIndex: setInitialState("previous", props.galleryData),
    }
  }

  goToNextImage = () => {
    if (this.state.currentIndex === this.props.galleryData.array.length - 2) {
      return this.setState({
        currentIndex: 0,
        nextIndex: 1,
        prevIndex: this.props.galleryData.array.length - 1,
      })
    } else if (this.state.currentIndex === 0) {
      return this.setState({
        currentIndex: 1,
        nextIndex: 2,
        prevIndex: 0,
      })
    }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex + 1,
      nextIndex: prevState.nextIndex + 1,
      prevIndex: prevState.prevIndex + 1,
    }))
  }

  goToPreviousImage = () => {
    if (this.state.currentIndex === 1) {
      return this.setState({
        currentIndex: this.props.galleryData.array.length - 1,
        nextIndex: 0,
        prevIndex: this.props.galleryData.array.length - 2,
      })
    } else if (
      this.state.currentIndex ===
      this.props.galleryData.array.length - 1
    ) {
      return this.setState({
        currentIndex: this.props.galleryData.array.length - 2,
        nextIndex: this.props.galleryData.array.length - 1,
        prevIndex: 2,
      })
    }

    this.setState(prevState => ({
      currentIndex: prevState.currentIndex - 1,
      nextIndex: prevState.nextIndex - 1,
      prevIndex: prevState.prevIndex - 1,
    }))
  }

  render() {
    return (
      <ArrowsContainer>
        <ArrowLink
          onClick={this.goToPreviousImage}
          to={`/${this.props.type}/${slugify(
            this.props.galleryData.array[this.state.prevIndex].node.image.file
              .fileName
          )}`}
        >
          <Arrow>&lt;</Arrow>
        </ArrowLink>
        <ArrowLink
          onClick={this.goToNextImage}
          to={`/${this.props.type}/${slugify(
            this.props.galleryData.array[this.state.nextIndex].node.image.file
              .fileName
          )}`}
        >
          <Arrow>&gt;</Arrow>
        </ArrowLink>
      </ArrowsContainer>
    )
  }
}

const setInitialState = (mode, galleryData) => {
  let arr = galleryData.array
  let idx = galleryData.index

  if (mode === "previous") {
    if (idx === 0) {
      return arr.length - 1
    } else {
      return idx - 1
    }
  } else if (mode === "next") {
    if (idx === arr.length - 1) {
      return 0
    } else {
      return idx + 1
    }
  }
}

export default GalleryNavigation
