import React, { useLayoutEffect, useState } from "react"
import styled from "styled-components"
import Image from "gatsby-image"
import { graphql } from "gatsby"

import measurements from "../styles/measurements"
import Layout from "../components/Layout"
import GalleryDetailInfo from "../components/GalleryDetailInfo"
import GalleryNavigation from "../components/GalleryNavigation"

const ImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
let maxHeight = 85

const Img = styled(Image)`
  width: 100%;
  height: auto;
  max-height: ${maxHeight}vh;
`

const DetailsWrapper = styled.div`
  position: fixed;
  bottom: ${measurements.layoutPadding}rem;
  left: ${measurements.layoutPadding}rem;
  transition: 0.1s ease-out all;

  @media only screen and (max-width: ${measurements.mobileBreakpoint}px) {
    bottom: ${measurements.layoutPadding / 2}rem;
    left: ${measurements.layoutPadding / 2}rem;
  }
`

export const query = graphql`
  query($fileName: String!) {
    contentfulDrawing(image: { file: { fileName: { eq: $fileName } } }) {
      title
      medium
      year
      dimensions
      image {
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    contentfulPrint(image: { file: { fileName: { eq: $fileName } } }) {
      title
      medium
      year
      dimensions
      image {
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    contentfulObject(image: { file: { fileName: { eq: $fileName } } }) {
      title
      medium
      year
      dimensions
      image {
        fluid(maxWidth: 1920, quality: 75) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

const GalleryDetail = props => {
  let [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    let timeout = false
    const delay = 250
    function updateWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener("resize", () => {
      // debounce
      clearTimeout(timeout)
      timeout = setTimeout(updateWidth, delay)
    })
    updateWidth()
    return () => {
      window.removeEventListener("resize", updateWidth)
    }
  }, [])

  const { pageContext } = props
  let image =
    props.data.contentfulDrawing ||
    props.data.contentfulPrint ||
    props.data.contentfulObject

  let galleryType = checkGalleryType(props)
  let aspectRatio = image.image.fluid.aspectRatio

  return (
    <Layout title={image.title}>
      <ImgWrapper>
        <Img
          fluid={image.image.fluid}
          style={{
            maxWidth:
              width > measurements.mobileBreakpoint
                ? `${maxHeight * aspectRatio}vh`
                : `${55 * aspectRatio}vh`,
          }}
        ></Img>
      </ImgWrapper>
      <DetailsWrapper>
        <GalleryNavigation
          galleryData={pageContext}
          type={galleryType}
        ></GalleryNavigation>
        <GalleryDetailInfo details={image}></GalleryDetailInfo>
      </DetailsWrapper>
    </Layout>
  )
}

const checkGalleryType = props => {
  if (props.data.contentfulDrawing) {
    return "drawings"
  } else if (props.data.contentfulPrint) {
    return "prints"
  } else if (props.data.contentfulObject) {
    return "objects"
  }
}

export default GalleryDetail
